<template>
  <svg
    :class="color"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5647 7.50922C14.5709 7.50918 14.5772 7.50918 14.5834 7.50918C16.6545 7.50918 18.3334 9.19117 18.3334 11.2661C18.3334 13.1998 16.8751 14.7923 15.0001 15M14.5647 7.50922C14.5771 7.37172 14.5834 7.23247 14.5834 7.09174C14.5834 4.55579 12.5314 2.5 10.0001 2.5C7.60278 2.5 5.63536 4.34389 5.43376 6.69327M14.5647 7.50922C14.4795 8.45633 14.1072 9.3205 13.5357 10.0138M5.43376 6.69327C3.32006 6.89478 1.66675 8.67825 1.66675 10.8486C1.66675 12.8681 3.09821 14.5527 5.00008 14.9394M5.43376 6.69327C5.5653 6.68073 5.69861 6.67431 5.83341 6.67431C6.7716 6.67431 7.63736 6.98495 8.33383 7.50918"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0001 10.8333V17.5M10.0001 10.8333C9.41658 10.8333 8.32636 12.4952 7.91675 12.9166M10.0001 10.8333C10.5836 10.8333 11.6738 12.4952 12.0834 12.9166"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'stroke-gray-500',
    },
  },
}
</script>
